
export const ICONS = {
  tree: {
    viewbox: '0 0 384 512',
    svgString: '<path d="M378.3 378.5L298.4 288h30.6c9 0 17-5 20.8-13.1 3.8-8 2.6-17.3-3.3-24.1L268.4 160h28.9c9.1 0 17.3-5.4 20.9-13.6 3.5-8.1 1.9-17.6-4.2-24.1L203.7 4.8c-6-6.5-17.3-6.5-23.3 0L70.1 122.3c-6.1 6.5-7.8 16-4.2 24.1C69.4 154.7 77.6 160 86.7 160h28.9l-78.1 90.9c-5.8 6.8-7.1 16-3.3 24C38 283 45.9 288 55 288h30.6L5.7 378.5c-6 6.8-7.4 16.1-3.6 24.3 3.8 8.1 12 13.3 21 13.3H160v24.5l-30.3 48.4c-5.3 10.6 2.4 23.2 14.3 23.2h96c11.9 0 19.6-12.5 14.3-23.2L224 440.5V416h136.9c9 0 17.3-5.2 21-13.3 3.8-8.2 2.4-17.5-3.6-24.3z"/>'
  },
  landmark: {
    viewbox: '0 0 512 512',
    svgString: '<path d="M501.6 92.1L267.2 2a32 32 0 0 0 -22.5 0L10.4 92.1A16 16 0 0 0 0 107.1V144c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-36.9c0-6.7-4.1-12.6-10.4-15zM64 192v160H48c-8.8 0-16 7.2-16 16v48h448v-48c0-8.8-7.2-16-16-16h-16V192h-64v160h-96V192h-64v160h-96V192H64zm432 256H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"/>',
  },
  mountain: {
    viewbox: '0 0 640 512',
    svgString: '<path d="M634.9 462.7l-288-448C341 5.5 330.9 0 320 0s-21 5.5-26.9 14.7l-288 448a32 32 0 0 0 -1.2 32.6A32 32 0 0 0 32 512h576c11.7 0 22.5-6.4 28.1-16.7a32 32 0 0 0 -1.2-32.6zM320 91.2L405.4 224H320l-64 64-38.1-38.1L320 91.2z"/>',
  },
  awPyramids: {
    viewbox: '0 0 72 72',
    svgString: `<g>
  <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="44.586 41.219 50.363 34.326 57.105 55"/>
  <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="69.527 55 50.363 34.326 57.105 55 59.945 55 61.61 55 69.527 55"/>
  <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="2.473 55 25.968 26.963 35.111 55 2.473 55"/>
  <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="39.449 55 51.957 55 25.968 26.963"/>
</g>`,
  },
  awColossus: {
    viewbox: '0 0 72 72',
    svgString: `<g>
    <line x1="34.3155" x2="32.4374" y1="17.7316" y2="15.8536" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <circle cx="35.9519" cy="19.3681" r="2.3143" fill="none" stroke-miterlimit="10" stroke-width="2"/>
    <line x1="35.9519" x2="35.9519" y1="17.0538" y2="14.368" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="37.7085" x2="39.6113" y1="17.8702" y2="15.9675" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="38.2662" x2="40.9513" y1="19.3681" y2="19.3681" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="33.6376" x2="30.9808" y1="19.3681" y2="19.3681" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M27.4081,13.4105l.1949,5.8847a3.4811,3.4811,0,0,0,.4992,1.691c1.2559,2.0753,3.2963,4.2817,4.7881,4.0953a30.0074,30.0074,0,0,1,6.1714,0,3.9932,3.9932,0,0,1,2.6291.8852,50.48,50.48,0,0,1,5.0175,5.1822,2.7609,2.7609,0,0,1,.18,3.4316l-2.59,3.6433"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M31.8073,9.8822c0,1.7456-1.9977,3.1606-4.462,3.1606s-4.4619-1.415-4.4619-3.1606Z"/>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="17.047 60.953 27.767 60.953 27.767 65"/>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="44.299 65 44.299 60.953 55.019 60.953"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M39.8324,29.3244l1.5149,9.5872L47.805,58.985l.0015-.0006a1.46,1.46,0,0,1-2.6723,1.1667l-7.33-16.9553a1.8584,1.8584,0,0,0-3.6578,0l-7.33,16.9553a1.46,1.46,0,0,1-2.6724-1.1667l.0016.0006,6.4577-20.0734,1.5148-9.5872"/>
  </g>`,
  },
  awGardens: {
    viewbox: '0 0 72 72',
    svgString: `<g>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="13.125 42.175 13.125 36.506 58.875 36.506 58.875 42.175"/>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="18.707 32.948 18.707 27.28 42.012 27.28"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M31.535,18.0983a6.3552,6.3552,0,0,0-7.2137,0"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M30.3584,13.0571a7.87,7.87,0,0,0-6.0371,5.0412"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.9427,15.8526a8.363,8.363,0,0,1,8.3786,2.2457"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.4227,19.2122a5.9185,5.9185,0,0,1,5.8986-1.1139"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21.372,12.8405c2.6979,1.0919,2.9493,5.2578,2.9493,5.2578"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M45.2871,29.7808a7.5552,7.5552,0,0,1,8.006-1.1138"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M47.6569,23.7216c4.347,0,5.6362,4.9454,5.6362,4.9454"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M61.6717,26.4212a8.3628,8.3628,0,0,0-8.3786,2.2458"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M59.1916,29.7808a5.9181,5.9181,0,0,0-5.8985-1.1138"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M56.2423,23.4091c-2.6979,1.0919-2.9492,5.2579-2.9492,5.2579"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M56.9709,36.5065a3.5581,3.5581,0,0,0-3.5581-3.558H18.5872a3.5581,3.5581,0,0,0-3.5581,3.558h0"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M64.2949,54.96V45.7332H7.7051V54.96H13.297v-.5436a4.07,4.07,0,1,1,8.1395,0V54.96h4.2827v-.5436a4.07,4.07,0,1,1,8.1394,0V54.96h4.2828v-.5436a4.07,4.07,0,1,1,8.1394,0V54.96h4.2827v-.5436a4.07,4.07,0,1,1,8.14,0V54.96Z"/>
    <line x1="24.3213" x2="24.3213" y1="18.0983" y2="32.9485" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="53.2931" x2="53.2931" y1="28.6671" y2="36.5065" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M23.08,42.1751H13.2855a3.5581,3.5581,0,0,0-3.558,3.5581h52.545a3.5581,3.5581,0,0,0-3.558-3.5581H41.475"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M38.013,42.1556c-3.1938,0-5.3868,3.5776-5.3868,3.5776a11.0275,11.0275,0,0,0-.06-4.9045"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M26.3928,42.74a6.41,6.41,0,0,1,6.2334,2.9936"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M43.8448,23.7216H24.3431a3.558,3.558,0,0,0-3.558,3.558H42.012"/>
  </g>`,
  },
  awLighthouse: {
    viewbox: '0 0 72 72',
    svgString: `<g>
    <line x1="36" x2="36" y1="7.6143" y2="10.0312" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <rect x="27.9402" y="32.6514" width="16.1197" height="3.5527" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <rect x="31.7709" y="20.2329" width="8.4581" height="12.4186" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="40.2291" x2="31.7709" y1="23.2333" y2="23.2333" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="41.1355" x2="30.8645" y1="20.2329" y2="20.2329" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <rect x="33.349" y="14.0294" width="5.2692" height="6.2034" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="27.9402" x2="27.0351" y1="32.6514" y2="31.0403" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="44.0598" x2="44.9649" y1="32.6514" y2="31.0403" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="36 10.031 39.269 14.029 32.731 14.029 36 10.031"/>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="41.224 59.486 38.942 40.197 33.058 40.197 30.776 59.486"/>
    <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="45.949 62.528 42.395 36.204 36 36.204 29.605 36.204 26.051 62.528 36 62.528 45.949 62.528"/>
    <line x1="5.004" x2="66.9975" y1="62.5276" y2="62.5276" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.0005"/>
  </g>`,
  },
  awMausoleum: {
    viewbox: '0 0 72 72',
    svgString: `<g>
    <rect x="20.6868" y="24.1421" width="30.6265" height="3.1025" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="49.443 24.142 40.051 14.751 31.922 14.751 22.557 24.115 49.443 24.142"/>
    <line x1="21.8397" x2="21.8397" y1="27.2447" y2="40.4947" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="27.5038" x2="27.5038" y1="27.2447" y2="40.4947" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="33.1679" x2="33.1679" y1="27.2447" y2="40.4947" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="38.8321" x2="38.8321" y1="27.2447" y2="40.4947" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="44.4962" x2="44.4962" y1="27.2447" y2="40.4947" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="50.1603" x2="50.1603" y1="27.2447" y2="40.4947" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="53.742 42.549 53.742 56.356 18.258 56.356 18.258 42.549 20.312 40.495 51.688 40.495 53.742 42.549"/>
    <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="53.739 56.361 55.24 57.862 55.24 60.857 36 60.857 16.76 60.857 16.76 57.862 18.261 56.361 53.739 56.361"/>
    <line x1="36" x2="36" y1="11.3735" y2="7.8195" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="31.9215" x2="30.9603" y1="14.7508" y2="9.9693" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="40.0513" x2="41.0125" y1="14.7508" y2="9.9693" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="34.0115" x2="34.0115" y1="12.7481" y2="9.9988" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="37.9885" x2="37.9885" y1="9.9988" y2="12.7481" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>`,
  },
  awZeus: {
    viewbox: '0 0 72 72',
    svgString: `<g>
    <circle cx="44.3317" cy="11.4161" r="2.8709" fill="none" stroke-miterlimit="10" stroke-width="2"/>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="43.551 22.011 41.461 34.982 32.501 26.129"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m31.882,41.6749c-.6095.0013-1.1032.495-1.1045,1.1045v1.3254c.0403.5925.512,1.0642,1.1045,1.1044h18.1135c.5925-.0402,1.0642-.5119,1.1045-1.1044v-18.2448c-.0013-.6095-.495-1.1032-1.1045-1.1045l-1.86.0208c-.5522,0-.463.4766-.463,1.1392l-.1814,15.7463-15.6091.0131Z"/>
    <line x1="32.0503" x2="24.4677" y1="30.3977" y2="30.3977" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="48.8626" x2="48.8626" y1="45.2092" y2="60.9583" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="32.6944" x2="32.6944" y1="45.2092" y2="60.6043" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="22.804 26.892 24.597 21.184 26.462 26.892 22.804 26.892"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m20.3976,18.8818c.955,1.3907,2.5139,2.2451,4.2,2.3018,0,0,3.1633,0,4.2713-2.3018"/>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="19.718 63.586 19.718 60.958 52.282 60.958 52.282 63.586"/>
    <line x1="34.1498" x2="40.6015" y1="15.2733" y2="60.9583" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m38.82,27.6428l.8914-5.9863c.3711-2.1276,2.2347-3.6693,4.3942-3.6351h0c2.1617.1147,3.3076,1.826,3.3846,3.9895l.0008,16.9628c-.0349,1.5041-1.2644,2.7052-2.7689,2.7049h-4.8171l-9.1318.0285-1.9552,16.89c-.223,1.3732-.9265,2.361-2.041,2.361-1.2263-.0331-2.1937-1.054-2.1606-2.2804.002-.0728.0075-.1455.0166-.2177v-17.1152c0-2.9821,2.41-5.094,5.7969-5.094h2.0712"/>
    <line x1="24.633" x2="24.633" y1="23.368" y2="26.2236" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <circle cx="24.633" cy="17.2251" r="0.6535" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>`,
  },
  awArtemis: {
    viewbox: '0 0 72 72',
    svgString: `<g>
    <line x1="59.0543" x2="12.9457" y1="47.7972" y2="47.7972" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="62.8369" x2="9.1631" y1="51.3786" y2="51.3786" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="66.2898" x2="5.7102" y1="55" y2="55" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="16" x2="16" y1="32.0481" y2="47.7972" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="24" x2="24" y1="32.0481" y2="47.7972" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="32" x2="32" y1="32.0481" y2="47.7972" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="40" x2="40" y1="32.0481" y2="47.7972" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="48" x2="48" y1="32.0481" y2="47.7972" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line x1="56" x2="56" y1="32.0481" y2="47.7972" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <polygon fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="59.054 28.07 12.946 28.07 36 20.705 59.054 28.07"/>
    <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="59.054 25.155 59.054 32.048 12.946 32.048 12.946 25.155"/>
    <line x1="36" x2="36" y1="20.7051" y2="18.3864" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>`,
  },
  aw: {
    viewbox: '',
    svgString: ``,
  },
};

const variantTemplate = (iconId, iconVariant) => `<symbol id="${iconId}-${iconVariant}" viewBox="0 0 1000 1000">
    <circle class="datamaps-marker-${iconVariant}-border" stroke-width="50" r="450" cx="500" cy="500"/>
    <use class="datamaps-marker-${iconVariant}-icon" href="#${iconId}-icon" x="200" y="200" height="600" width="600"/>
  </symbol>`;

const template = (iconId, iconViewbox, iconSvg) => `<svg xmlns="http://www.w3.org/2000/svg">
  <g id="${iconId}-group">
    <symbol id="${iconId}-icon" viewbox="${iconViewbox}">${iconSvg}</symbol>
    ${variantTemplate(iconId, 'visited')}
    ${variantTemplate(iconId, 'unvisited')}
  </g>
</svg>`;

const createSvgElement = (svgString) => {
  if (typeof window === `undefined`) {
    return null;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'text/html');
  return  doc.querySelector('g');
};

Object.keys(ICONS).forEach(iconId => {
  const icon = ICONS[iconId];
  icon.svgElement = createSvgElement(template(iconId, icon.viewbox, icon.svgString));
});
 
